import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";

import { useDevice } from "../../../../domain/device";
import { useApi } from "../../../../api";
import List from "./List";
import DeleteContainer from "./DeleteContainer";
import Billing from "./Billing";
import DebtorView from "./DebtorView";
import EditTerms from "./EditTerms";
import CreateTerms from "./CreateTerms";
import Debtor from "./Debtor";
import { REGEXP_ID } from "../../../../utils/stringUtils";
import OrderNumberView from "./OrderNumberView";
import RecipientsAddressView from "./RecipientsAddressView";
import RecipientAddressDialog from "./RecipientAddress/RecipientAddressDialog";
import OrderNumberDialog from "./OrderNumber";

const BillingContainer = () => {
  const { device, reload: reloadDevice } = useDevice();
  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [termsList, setTermsList] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [confirmDeleteTerms, setConfirmDeleteTerms] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);
    setLoading(true);

    api
      .get(`/v2/devices/${device.id}/billing-terms`)
      .then(response => {
        setTermsList(response.data);
        setLoading(false);
      })
      .catch(() => {})
      .then(() => setReload(false));
  }, [api, device, reload]);

  const handleConfirmDelete = terms => {
    setConfirmDeleteTerms(terms);
  };

  const handleEditTerms = terms => {
    history.push(`${url}/${terms.id}`);
  };

  const handleCreateTerms = () => history.push(`${url}/new`);

  const handleEditDebtor = () => history.push(`${url}/debtor`);
  const handleEditAddress = () => history.push(`${url}/recipient`);
  const handleEditOrderNumber = () => history.push(`${url}/order-number`);

  const handleClose = () => {
    setReload(true);
    history.push(url);
  };

  const handleCloseDebtor = () => {
    reloadDevice();

    history.push(url);
  };

  useEffect(() => {
    if (!device) {
      return;
    }

    setReload(true);
  }, [device]);

  return (
    <>
      <Switch>
        <Route path={`${url}/debtor`}>
          <Debtor device={device} onClose={handleCloseDebtor} />
        </Route>
        <Route path={`${url}/recipient`}>
          <RecipientAddressDialog device={device} onClose={handleCloseDebtor} />
        </Route>
        <Route path={`${url}/order-number`}>
          <OrderNumberDialog device={device} onClose={handleCloseDebtor} />
        </Route>
        <Route path={`${url}/new`}>
          <CreateTerms device={device} onClose={handleCloseDebtor} />
        </Route>
        <Route path={`${url}/:termsId(${REGEXP_ID})`}>
          {({
            match: {
              params: { termsId }
            }
          }) => (
            <EditTerms
              device={device}
              termsId={termsId}
              onClose={handleClose}
            />
          )}
        </Route>
      </Switch>
      {confirmDeleteTerms !== null && (
        <DeleteContainer
          termsId={confirmDeleteTerms.id}
          onCancel={() => setConfirmDeleteTerms(null)}
          onDeleted={() => {
            setReload(true);
            setConfirmDeleteTerms(null);
          }}
        />
      )}
      <Billing
        ListComponent={
          <List
            isLoading={isLoading}
            termsList={termsList}
            onCreateTerms={handleCreateTerms}
            onEditTerms={handleEditTerms}
            onDeleteTerms={handleConfirmDelete}
          />
        }
        DebtorComponent={
          <DebtorView onEditDebtor={handleEditDebtor} device={device} />
        }
        OrderNumberComponent={
          <OrderNumberView
            device={device}
            onEditOrderNumber={handleEditOrderNumber}
          />
        }
        RecipientAddressComponent={
          <RecipientsAddressView
            device={device}
            onEditAddress={handleEditAddress}
          />
        }
        shouldShowDebtorDetails={
          device.debtor !== null &&
          device.debtor.ident !== null &&
          device.debtor.ident !== ""
        }
      />
    </>
  );
};

export default BillingContainer;
