import React, { useState } from "react";
import OrderNumberForm from "./OrderNumberForm";

import { useApi } from "../../../../../api";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";

const OrderNumberContainer = ({ device, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = orderNumber => {
    setSubmitting(true);

    api
      .put(`/v2/devices/${device.id}/billing-recipient`, {
        order_number: orderNumber,
        recipient_address: device.recipientAddress
      })
      .then(() => {
        enqueueSnackbar("devices.settings.billing.responses.debtorUpdated", {
          variant: "success"
        });

        setSubmitting(false);
        onClose(true);
      })
      .catch(e => {
        setSubmitting(false);
        if (!e.response) {
        }
      });
  };

  return (
    <OrderNumberForm
      value={device.orderNumber}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
};

export default OrderNumberContainer;
