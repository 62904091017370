import React from "react";
import { DialogContent } from "@material-ui/core";
import FixedPositionDialog from "../../../../../elements/FixedPositionDialog";
import DialogTitleWithCloseIcon from "../../../../../elements/DialogTitleWithCloseIcon";
import OrderNumberContainer from "./OrderNumberContainer";
import I18nText from "../../../../../elements/I18nText";

const OrderNumberDialog = ({ device, onClose }) => {
  return (
    <>
      <FixedPositionDialog open={true} scroll="body" fullWidth={true}>
        <DialogTitleWithCloseIcon onClose={onClose}>
          <I18nText>devices.settings.billing.sections.order</I18nText>
        </DialogTitleWithCloseIcon>
        <DialogContent>
          <OrderNumberContainer device={device} onClose={onClose} />
        </DialogContent>
      </FixedPositionDialog>
    </>
  );
};

export default OrderNumberDialog;
