import React, { useEffect } from "react";
import { Checkbox, FormControlLabel, Grid, MenuItem } from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthSelect } from "../../../../../../elements/FormField";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";
import { IOT_VERSION } from "../../../../../../domain/device";
import { useField, useFormikContext } from "formik";
import { ROLES, useAuth } from "../../../../../../auth";

const IotSendInterval = React.memo(() => {
  const [{ value: iotVersion }] = useField("iotVersion");
  const [{ value: iotSendInterval }] = useField("iotSendInterval");
  const { setFieldValue } = useFormikContext();
  const { user, hasRole } = useAuth();
  const hasRoleMaster = hasRole(user, ROLES.MASTER);
  const [enabled, setEnabled] = React.useState(iotSendInterval !== 0);

  const options =
    iotVersion === IOT_VERSION.V1 ? [360, 480, 600, 720] : [360, 720];
  if (!enabled) {
    if (hasRoleMaster) {
      options.unshift(0);
    } else {
      return;
    }
  }

  const defaultOption = options[0];

  const bubbleHelpId =
    iotVersion === IOT_VERSION.V2
      ? "devices.edit.iotSendIntervalV2"
      : "devices.edit.iotSendInterval";

  useEffect(() => {
    if (!options.includes(iotSendInterval)) {
      setFieldValue("iotSendInterval", defaultOption);
    }
  }, [iotVersion, options, iotSendInterval, setFieldValue, defaultOption]);

  const updateEnabled = newEnabled => {
    if (!newEnabled) {
      setFieldValue("iotSendInterval", 0);
    }
    setEnabled(newEnabled);
  };

  return (
    <Grid container spacing={2}>
      {hasRoleMaster && (
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => {
                  updateEnabled(e.target.checked);
                }}
                checked={enabled}
                color="primary"
              />
            }
            label={"Send Interval"}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={hasRoleMaster ? 8 : 12}>
        <BubbleHelpFieldWrapper id={bubbleHelpId}>
          <FullWidthSelect
            fastField={false}
            variant="outlined"
            disabled={!enabled}
            required
            label={
              iotVersion === IOT_VERSION.V2 ? (
                <I18nText>
                  devices.settings.configuration.iotSendIntervalV2
                </I18nText>
              ) : (
                <I18nText>
                  devices.settings.configuration.iotSendInterval
                </I18nText>
              )
            }
            name="iotSendInterval"
            inputProps={{
              id: "iot-send-interval-select"
            }}
          >
            {options.map(option => (
              <MenuItem key={option} value={option}>
                <I18nText>{`devices.settings.configuration.iotSendIntervalOptions.${option}`}</I18nText>
              </MenuItem>
            ))}
          </FullWidthSelect>
        </BubbleHelpFieldWrapper>
      </Grid>
    </Grid>
  );
});

export default IotSendInterval;
