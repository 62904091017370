import React from "react";
import { Box, Grid, TextField } from "@material-ui/core";

import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../../elements/Button";
import DebtorSelect from "../../../../../elements/FormField/DebtorSelect";
import I18nText from "../../../../../elements/I18nText";

const RecipientAddressForm = ({ value, onClose, isSubmitting, onSubmit }) => {
  const [address, setAddress] = React.useState(value);
  const handleSubmit = () => {
    onSubmit(address);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginBottom={4}>
            <TextField
              name="recipientAddress"
              value={address}
              onChange={e => setAddress(e.target.value)}
              label={
                <I18nText>
                  devices.settings.billing.sections.recipients
                </I18nText>
              }
              variant="outlined"
              multiline={true}
              fullWidth={true}
              minRows={4}
              maxRows={5}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonsWrapper
            leftButton={
              <CancelButton onClick={onClose} disabled={isSubmitting} />
            }
            rightButton={
              <SaveButton onClick={handleSubmit} disabled={isSubmitting} />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RecipientAddressForm;
