import React from "react";

import DescriptionList from "../../../../elements/DescriptionList";
import I18nText from "../../../../elements/I18nText";
import Section from "../../../../elements/Section";
import SectionHeader from "../../../../elements/SectionHeader";

const OrderNumberView = ({ device, onEditOrderNumber }) => {
  const options = [
    {
      label: "devices.settings.billing.orderNumber",
      value: device.orderNumber ? (
        device.orderNumber
      ) : (
        <I18nText>devices.settings.billing.noOrderNumber</I18nText>
      )
    }
  ];

  return (
    <Section>
      <SectionHeader onEdit={onEditOrderNumber}>
        <I18nText>devices.settings.billing.sections.order</I18nText>
      </SectionHeader>
      <DescriptionList options={options} />
    </Section>
  );
};

export default OrderNumberView;
