import React, { useState } from "react";
import { Box, Grid, TextField } from "@material-ui/core";

import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../../elements/Button";
import DebtorSelect from "../../../../../elements/FormField/DebtorSelect";
import I18nText from "../../../../../elements/I18nText";

const OrderNumberForm = ({ value, onClose, isSubmitting, onSubmit }) => {
  const [orderNumber, setOrderNumber] = useState(value);

  const handleSubmit = () => {
    onSubmit(orderNumber);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginBottom={4}>
            <TextField
              name="orderNumber"
              value={orderNumber}
              onChange={e => {
                setOrderNumber(e.target.value);
              }}
              label={<I18nText>devices.settings.billing.orderNumber</I18nText>}
              variant="outlined"
              fullWidth={true}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonsWrapper
            leftButton={
              <CancelButton onClick={onClose} disabled={isSubmitting} />
            }
            rightButton={
              <SaveButton onClick={handleSubmit} disabled={isSubmitting} />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OrderNumberForm;
