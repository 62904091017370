import React from "react";
import { MenuItem } from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthSelect } from "../../../../../../elements/FormField";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

const IotGpsInterval = React.memo(() => {
  const options = [5, 10, 20];

  return (
    <BubbleHelpFieldWrapper id="devices.edit.iotGpsInterval">
      <FullWidthSelect
        fastField={false}
        variant="outlined"
        required
        label={
          <I18nText>devices.settings.configuration.iotGpsInterval</I18nText>
        }
        name="iotGpsInterval"
        inputProps={{
          id: "iot-gps-interval-select"
        }}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <I18nText>{`devices.settings.configuration.iotGpsIntervalOptions.${option}`}</I18nText>
          </MenuItem>
        ))}
      </FullWidthSelect>
    </BubbleHelpFieldWrapper>
  );
});

export default IotGpsInterval;
