import React from "react";

import DescriptionList from "../../../../elements/DescriptionList";
import I18nText from "../../../../elements/I18nText";
import Section from "../../../../elements/Section";
import SectionHeader from "../../../../elements/SectionHeader";
import { nl2br } from "../../../../utils/stringUtils";

const RecipientAddressView = ({ device, onEditAddress }) => {
  const options = [
    {
      label: "devices.settings.billing.sections.recipients",
      value: device.recipientAddress ? (
        nl2br(device.recipientAddress)
      ) : (
        <I18nText>devices.settings.billing.noRecipientAddress</I18nText>
      )
    }
  ];

  return (
    <Section>
      <SectionHeader onEdit={onEditAddress}>
        <I18nText>devices.settings.billing.sections.recipients</I18nText>
      </SectionHeader>
      <DescriptionList options={options} />
    </Section>
  );
};

export default RecipientAddressView;
