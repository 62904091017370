import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useApi } from "../api";
import { useObjectConfig } from "../objectConfig";
import DeviceContext from "./DeviceContext";
import useI18nSnackbar from "../hooks/useI18nSnackbar";

const DeviceProvider = ({ deviceId, children }) => {
  const api = useApi();
  const { get: getConfig } = useObjectConfig();
  const [loadingCount, setLoadingCount] = useState(2);
  const [reload, setReload] = useState(true);
  const [device, setDevice] = useState(null);
  const [deviceConfig, setDeviceConfig] = useState(null);
  const { enqueueSnackbar } = useI18nSnackbar();
  const history = useHistory();

  const doReload = () => setReload(true);

  useEffect(() => {
    setReload(true);
  }, [deviceId]);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);

    api
      .get(`/v2/devices/${deviceId}`)
      .then(response => setDevice(response.data))
      .catch(() => {
        history.push("/devices");
      })
      .then(() => setLoadingCount(loadingCount => loadingCount - 1));

    getConfig([
      {
        obj: "device",
        id: deviceId,
        key: "ui.consumption.excludedSensors"
      },
      {
        obj: "device",
        id: deviceId,
        key: "ui.consumption.excludedYears"
      },
      {
        obj: "device",
        id: deviceId,
        key: "ui.process.defaultTimePeriod"
      },
      {
        obj: "device",
        id: deviceId,
        key: "ui.process.excludedSensors"
      },
      {
        obj: "device",
        id: deviceId,
        key: "ui.process.showTemperatureCurve"
      }
    ])
      .then(data => {
        setDeviceConfig(data);
      })
      .catch(() => {})
      .then(() => setLoadingCount(loadingCount => loadingCount - 1));
  }, [deviceId, getConfig, reload, api, enqueueSnackbar, history]);

  const contextValue = { device, deviceConfig, reload: doReload };

  return loadingCount > 0 ? null : (
    <DeviceContext.Provider value={contextValue}>
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceProvider;
