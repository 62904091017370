import React from "react";
import { Box, Grid } from "@material-ui/core";

const Billing = ({
  ListComponent,
  DebtorComponent,
  OrderNumberComponent,
  RecipientAddressComponent,
  shouldShowDebtorDetails
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {ListComponent}
        </Grid>
      </Grid>

      <Grid container spacing={2} direction={"row"}>
        <Grid item xs={12} md={6}>
          <Box marginTop={4}>{DebtorComponent}</Box>
        </Grid>
        {shouldShowDebtorDetails && (
          <>
            <Grid item xs={12} md={6}>
              <Box marginTop={4}>{RecipientAddressComponent}</Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box marginTop={4}>{OrderNumberComponent}</Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Billing;
